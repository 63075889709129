import * as Logger from 'WaLogger'; 

const $ = require('jquery');

const CustomPushy = require('slider-menu');
const MenuTooltip = require('menu-tooltip');

/**
* Constructs multiple instances of CustomPushy for the Archive/Posts page.
* Checks that it IS the posts page - $(body.posts).length !=== 0
*
* Finds the menu root ul and the activation button for each of
*	-	archive-by-title
*	-	archive-by-date
*	-	archive-by-country
*	-	archive-by-tag
*
*	The convention is:
*
*	-	no submenus
*	-	top ul is $('ul#archive-by-XXXX') and this matches up with $('#archive-by-XXXX-button')
*
*/
const createArchiveMultiLeftMenus = function createArchiveMultiLeftMenus() {
	const names = [
		'archive-by-title',
		'archive-by-date',
		'archive-by-country',
		'archive-by-tag',
	];
	if ($('body.posts').length === 0) {
		Logger.log('createArchiveMultiLeftMenus:: nothing to do');
		return null;
	}
	const menus = [];
	const containers = $('#wa-top-bar, body > .container, #footer');
	names.forEach((ix, entry) => {
	// for (const entry of names) {
		const button = $(`#${entry}-button`);
		const menu_root = $(`ul#${entry}`);
		// const pushy = {
		// 	menu_root,
		// 	button,
		// };
		if (button.length !== 0 && menu_root.length !== 0) {
			Logger.log(['createMult', button, menu_root]);
			const p = new CustomPushy(menu_root, button, containers, entry);
			menus.push(p);
		} else {
			Logger.log('createMultipleLeftMenus: button or root empty');
		}
	});
	return menus;
};


/**
* Create a single instance of CustomPushy from the existing markup and
* the passed in button element.
*
* Assumes the top menu <ul> has class left-menu and that there is ONLY one of them
*
* Constructs a jquery set of elements to be moved aside when menu options,
* #top-bar + body > .container + #footer
*
*	returns a CustomPushy instance
*
*/
const createSingleLeftMenu = function createSingleLeftMenu(button_selector) {
	Logger.log('leftMenu initializing');
	const button = $(button_selector);
	if (button.length === 0) {
		Logger.log(`leftMenu: no button with selector ${button_selector} found - nothing to do`);
		return null;
	}
	//
	// setup to use pushy
	//
	// find all root level menus - should be only one
	// the button
	// other elements that need to be pushed aside for the menu
	const m = $('.left-menu').not('.left-sub-menu');

	if (m.length > 1) {
		throw new Error('too many top level side menus');
	} else if (m.length === 0) {
		Logger.log('leftMenu : nothing to do');
		return null;
	}
	//
	// all top level children of 'body' should be pushed aside when the menu opens
	//
	const containers = $('#wa-top-bar, body > .container, #footer');
	Logger.log(`leftMenu : containers : ${containers.length}`);

	const c_pushy = new CustomPushy(m, button, containers);
	return c_pushy;
};


const createVehicleLeftMenu = function createVehicleLeftMenu() {
	if ($('body.vehicle').length === 0) {
		Logger.log('createVehicleLeftMenu:: nothing to do');
		return null;
	}

	const ret = createSingleLeftMenu('#vehicle-button');
	MenuTooltip('#menu-column a', 'e');
	return ret;
};
const createCampingLeftMenu = function createCampingLeftMenu() {
	if ($('body.camping').length === 0) {
		Logger.log('createCampingLeftMenu:: nothing to do');
		return null;
	}
	const ret = createSingleLeftMenu('#camping-button');
	MenuTooltip('#menu-column a', 'e');
	return ret;
};
const createHowLeftMenu = function createHowLeftMenu() {
	if ($('body.how').length === 0) {
		Logger.log('createHowLeftMenu:: nothing to do');
		return null;
	}
	const ret = createSingleLeftMenu('#how-button');
	MenuTooltip('#menu-column a', 'e');
	return ret;
};

export default {
	vehicleMenu : createVehicleLeftMenu,
	campingMenu : createCampingLeftMenu,
	howMenu		: createHowLeftMenu,
	archiveMenu : createArchiveMultiLeftMenus,
};


// module.exports = {
// 	vehicleMenu : createVehicleLeftMenu,
// 	campingMenu : createCampingLeftMenu,
// 	howMenu		: createHowLeftMenu,
// 	archiveMenu : createArchiveMultiLeftMenus,
// };
