import * as Logger from 'WaLogger';

const $ = require('jquery');
const Lodash_ = require('lodash');
const Backbone = require('backbone');

function alertResponseText(xhr_response_text) {
	alert(xhr_response_text); // eslint-disable-line no-alert
}

//
// This is an experiment in decoding gpx document objects. PROBABLY OBSOLETE
//
const MapExp = function MapExp(gpx_doc) {
	this.tracks 	= gpx_doc.getElementsByTagName('trk');
	this.trackSegs 	= gpx_doc.getElementsByTagName('trkseg');
	this.bounds = gpx_doc.getElementsByTagName('bounds');
	this.minlat = this.bounds[0].getAttribute('minlat');
	this.maxlat = this.bounds[0].getAttribute('maxlat');
	this.minlon = this.bounds[0].getAttribute('minlon');
	this.maxlon = this.bounds[0].getAttribute('maxlon');

	this.bounds = function MapExp_bounds() {
		throw new Error('not implemented');
		// this._bounds = new google.maps.LatLngBounds(
		// 	new google.maps.LatLng(this.minlat, this.minlon),
		// 	new google.maps.LatLng(this.maxlat, this.maxlon)
		// );
		// return this._bounds;
	};

	Logger.log(['MapExp', this.tracks, this.trackSegs, this.bounds]);
};


// SHOULD REWORK TO USE A BASE CLASS
export class BaseModel { // eslint-disable-line no-unused-vars
	constructor(url) {
		this.className = 'ModelBase';
		this.url = url;
		this.isLoaded = false;
		this.status = 'not loaded';
		Lodash_.extend(this, Backbone.Events);
	}

	setStatus(newStatus) {
		this.status = newStatus;
		this.trigger('change', this);
	}

	//
	// This method should be overridden to parse into model specific attributes
	//
	parseData(data) { // eslint-disable-line no-unused-vars
		throw new Error(`derived classes must provide their own parseData method`);
	}

	load() {
		const self = this;
		$.get({
			url: this.url,
			dataType: 'json',
			success: (data) => {
				setTimeout(() => {
					Logger.log(['CampingModel ajax success', self]);
					self.data = data;
					this.parseData(data);
					self.isLoaded = true;
					self.setStatus('successfully loaded');
				}, 0);
			},
			error(xhr, statusText, error) {
				const txt = xhr.responseText;
				self.setStatus('ajax load failed');
				alertResponseText(txt);
				throw new Error(`bad response from ajax call ${statusText} ${error}`);
			},
		});
	}
}

//
// 	A model of a gps track in the form of a gpx file with a single track and single track segment.
//	assumed to have <bounds> elements for bounding the map
//
export class TracksModel {
	constructor(url) {
		this.url = url;
		this.status = 'not loaded';
		this.isLoaded = false;
		this.gpx_doc = null;

		Lodash_.extend(this, Backbone.Events);
	}

	setStatus(newStatus) {
		this.status = newStatus;
		this.trigger('change', this);
	}

	load() {
		const self = this;
		this.setStatus('loading');
		$.get({
			url: this.url,
			dataType: 'xml',
			success: (data) => {
				Logger.log(['GpxTracksModel ajax success', data]);
				self.isLoaded = true;
				self.gpx_doc = data;
				self.mapExp = new MapExp(data);
				self.setStatus('successfully loaded'); // this triggers change
			},
			error(xhr, statusText, error) {
				const txt = xhr.responseText;
				self.setStatus('ajax load failed');
				alertResponseText(txt);
				throw new Error(`bad response from ajax call ${statusText} ${error}`);
			},
		});
	}
}

//
// A model for camping locations and related whiteacorn data. Custom json format
//
export class CampingModel {
	constructor(url) {
		this.status = 'not loaded';
		this.isLoaded = false;
		this.url = url;
		this.markers = false;

		Lodash_.extend(this, Backbone.Events);
	}

	setStatus(newStatus) {
		this.status = newStatus;
		this.trigger('change', this);
	}

	parseData(data) {
		this.return_code = data.return_code;
		this.markers = data.markers;
	}

	load() {
		const self = this;
		$.get({
			url: this.url,
			dataType: 'json',
			success: (data) => {
				setTimeout(() => {
					Logger.log(['CampingModel ajax success', self]);
					self.data = data;
					this.parseData(data);
					self.isLoaded = true;
					self.setStatus('successfully loaded');
				}, 0);
			},
			error(xhr, statusText, error) {
				const txt = xhr.responseText;
				self.setStatus('ajax load failed');
				alertResponseText(txt);
				throw new Error(`bad response from ajax call ${statusText} ${error}`);
			},
		});
	}
}

export class CurrentTripModel {
	constructor(url) {
		this.status = 'not loaded';
		this.isLoaded = false;
		this.url = url;
		this.markers = false;

		Lodash_.extend(this, Backbone.Events);
	}

	setStatus(newStatus) {
		this.status = newStatus;
		this.trigger('change', this);
	}

	parseData(data) {
        this.payload = {
            return_code: data.return_code,
            markers: data.markers,
        }
		this.return_code = data.return_code;
		this.markers = data.markers;
        if(data.hasOwnProperty("track")) {
            console.log(["This is a single polyline map", data.track])
            this.payload.tracks = {
                multiple_polylines: true,
                tracks: [data.track]
            }
            // this.tracks = [data.track];
            // this.multiple_polylines = true
        } else if(data.hasOwnProperty("tracks")) {
            console.log(["This is a multiple polyline map", data.tracks])
            this.payload.tracks = {
                tracks: data.tracks,
                multiple_polylines: true
            }
        } else {
            throw Error("Illegal specification of polylines")
        }
        console.log("end parsing")
	}

	load() {
		const self = this;
		$.get({
			url: this.url,
			dataType: 'json', // here it is
			success: (data) => {
				setTimeout(() => {
					Logger.log(['CurrentTripModel ajax success', self]);
					self.data = data;
					this.parseData(data);
					self.isLoaded = true;
					self.setStatus('successfully loaded');
				}, 0);
			},
			error(xhr, statusText, error) {
				const txt = xhr.responseText;
				self.setStatus('ajax load failed');
				alertResponseText(txt);
				throw new Error(`bad response from ajax call ${statusText} ${error}`);
			},
		});
	}
}

//
// The TabManager is being treated as a model - could make it the "MainView" also
//
export class TabManager {
	constructor(tabSelector) {
		this.tabSelector = tabSelector;
		Lodash_.extend(this, Backbone.Events);
	}

	load() {
		$(this.tabSelector).tabs({
			create: (event, ui) => {
				Logger.log(['TabManager', 'create event ui ', ui]);
				this.trigger('create', ui, this);
			},
			activate: (event, ui) => {
				Logger.log(['TabManager', 'activate event ui ', ui]);
				this.trigger('activate', ui, this);
			},
		});
	}
}
