let enabled = true;

export const writeln = function writeln(str) {
	var ds = (new Date()).toTimeString()
	if (enabled) {
		console.log(ds + ":" + str); // eslint-disable-line no-console
	}
};

export const enable = function enable() {
	enabled = true;
};

export const disable = function disable() {
	enabled = false;
};

export const log = writeln;
