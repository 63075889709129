import * as Logger from 'WaLogger';
import * as MapControllers from 'MapController';

const $ = require('jquery');


export function initialize() {
	const default_options = {
		map_type		: 'site-map',
		url				: '/some-url',

		fit_bounds 		: true,
		wrap_selector 	: '#w_map_content',
		canvas_selector	: '#w_map_canvas',
		status_selector	: '.w_map_status',
		spinner_class	: 'map-loading-spinner',
		spinner_image	: '/skins/rtw/images/spinner.gif',
	};

	if (typeof window.WhiteacornMap === 'undefined') {
		Logger.log(['mapBehavior not required ']);
		return;
	}
	if (typeof window.WhiteacornMap !== 'object'
		|| window.WhiteacornMap == null
		|| (typeof window.WhiteacornMap === 'object' && typeof window.WhiteacornMap.options === 'undefined')
		|| (typeof window.WhiteacornMap === 'object'
				&& typeof window.WhiteacornMap.options === 'object'
				&& typeof window.WhiteacornMap.options.map_type === 'undefined')
	) {
		Logger.log(['mapBehavior ERROR malformed options ', window.WhiteacornMap]);
		throw new Error('mapBehavior ERROR malformed options ');
	}
	const opts = window.WhiteacornMap.options;

	const options = Object.assign(default_options, opts);

	// Now choose the correct controller function
	switch (options.map_type) {
	case 'site_map':
		Logger.log(['map_behaviour', 'plain', options]);
		MapControllers.SiteCampingMapController(options);
		break;
	case 'markers_only':
		Logger.log(['map_behaviour', 'plain', options]);
		MapControllers.MarkersOnlyMapController(options);
		break;
	case 'markers_with_track':
		Logger.log(['map_behaviour', 'plain', options]);
		MapControllers.MarkersWithTrackMapController(options);
		break;
	default:
		throw new Error(`map-behaviour found no recognized map type = ${options.map_type}`);
	}
}
