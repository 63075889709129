import * as Logger from 'WaLogger'

const $ = require('jquery')
const OnBigSmall = require('on-screen-big-small')

export function initialize(selector) {
	const columns = $(selector)
	let currentHeight
	let tallestcolumn = 0
	let isBig = false

	const setColumns = function setColumns(pcolumns) {
		pcolumns.each((unused_ix, element) => { // eslint-diable no-unused-vars
			currentHeight = Math.max(element.scrollHeight, element.clientHeight)
			Logger.log([`colequal currentHeight `, currentHeight])
			if (currentHeight > tallestcolumn) {
				tallestcolumn = currentHeight
			}
		});
		columns.height(tallestcolumn)
	};

	const resetColumns = function resetColumns(pcolumns) {
		pcolumns.each(() => {
			$(this).css('height', '')
		});
	};

	const onGoingLarge = () => {
		Logger.log('equalHeight::GOING MEDIUM screen entered');
		if (!isBig) {
			setColumns(columns)
			isBig = true;
		}
	};

	const onGoingSmall = () => {
		Logger.log('equalHeight::GOING SMALL screen entered');
		if (isBig) {
			resetColumns(columns)
			isBig = false;
		}
	};

	OnBigSmall.register(onGoingLarge, onGoingSmall);
}
