import * as Logger from 'WaLogger';

const $ = require('jquery');
const Lodash_ = require('lodash');
const Backbone = require('backbone');

class TabPanelObject {
	constructor(ix, key, keyEl, panelEl) {
		this.ix = ix;
		this.key = key;
		this.keyEl = keyEl;
		this.panelEl = panelEl;
	}
}

export default class TabWidget {
	constructor(selector) {
		this.className = 'TabWidget';
		const self = this;
		Lodash_.extend(this, Backbone.Events);
		this.tabs = $(selector);
		const tabTable = [];
		const keys = [];
		this.tabs.find('ul > li > a').each(function this_each(ix, en) {
			Logger.log([self.className, ix, en, this.attributes.href.value, this]);
			const tabKey = this.attributes.href.value;
			const panel	= $(tabKey)[0];
			$(panel).hide();
			keys[tabKey] = ix;
			tabTable[ix] = new TabPanelObject(ix, tabKey, this, panel);
			$(this).parent().click(ix, function this_each_click() {
				Logger.log([self.className, 'click handler', ix, this]);
				self.makeCurrent(ix);
			});
		});
		this.currentIx = 0;
		this.tabTable = tabTable;
		this.keys = keys;
	}

	setFirstTab(key) {
		this.firstPanel(this.keys[key]);
	}

	firstPanel(newIx) {
		const obj = this.tabTable[newIx];
		const newPanelEl = obj.panelEl;
		const newKeyEl = obj.keyEl;
		const newKey = obj.key;
		this.currentIx = newIx;
		$(newKeyEl).parent().addClass('tab_selected');
		$(newPanelEl).show();
		this.trigger('create', {
			key 	: newKey,
			panel	: newPanelEl,
		});
	}

	makeCurrent(newIx) {
		const previousIx = this.currentIx;
		const previousPanelEl = this.tabTable[previousIx].panelEl;
		const previousKeyEl = this.tabTable[previousIx].keyEl;
		const previousKey = this.tabTable[previousIx].key;
		const obj = this.tabTable[newIx];
		const newPanelEl = obj.panelEl;
		const newKeyEl = obj.keyEl;
		const newKey = obj.key;
		$(previousPanelEl).hide();
		$(previousKeyEl).parent().removeClass('tab_selected');
		this.currentIx = newIx;
		$(newPanelEl).show();
		$(newKeyEl).parent().addClass('tab_selected');
		this.trigger('change activate', {
			oldKey 		: previousKey,
			oldPanel	: previousPanelEl,
			newPanel	: newPanelEl,
			newKey,
		});
	}
}
// export TabWidget;
// module.exports = TabWidget;
