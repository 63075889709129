import TabWidget from 'TabWidget';
import MapManager from 'LeafletMapManager';
import * as Logger from 'WaLogger';
import LoadingSpinner from 'MapSpinner';

const $ = require('jquery');

function hasOwnProperty(object, prop) { // eslint-disable-line no-unused-vars
	return Object.hasOwnProperty.call(object, prop);
}

//
// Base class for the two map-type views.
//
// Expects to have a .map-canvas and .map_status as immediate children of selector
//
class MapBaseView 
{
	constructor(model, options) {
		this.className = 'MapBaseView';

		this.options = options;
		this.$wrap = $(options.wrap_selector);
		if (this.$wrap.length === 0) {
			throw new Error(`MapBaseView - did not find selector : ${selector}`);
		}

		const canvas_selector = `${this.options.wrap_selector} > ${this.options.canvas_selector}`;
		this.$canvas = $(canvas_selector);
		// this.$canvas = $(`${this.options.wrap_selector} > ${this.options.canvas_selector}`);

		if (this.$canvas.length === 0) {
			throw new Error(`MapBaseView - did not find selector : ${this.options.wrap_selector} > ${this.options.canvas_selector}`);
		}

		this.canvas = this.$canvas[0];
		this.$status = $(`${this.options.wrap_selector} > ${this.options.status_selector}`);

		if (this.$status.length === 0) {
			throw new Error(`MapViewBase - did not find ${this.options.wrap_selector} > ${this.options.status_selector}`);
		}

		this.el = this.$canvas;
		// means this views tab is showing
		this.isDisplayed = false;
		// means that it has already been rendered in final form
		this.hasBeenRendered = false;
		this.model = model;
		this.loadingSpinner = new LoadingSpinner(this.$canvas, this.options);

		model.on('change', () => {
			Logger.log([this.className, 'on change fired']);
			this.render();
		});
	}

	drawMap() {
		throw new Error(`${this.className} - derived classes must provide their own drawMap`);
	}

	render() {
		// if( ! this.isDisplayed )
		// 	return
		Logger.log([this.className, ' entered render', this.model.status, this.mode]);
		if (this.hasBeenRendered) {
			return;
		}
		Logger.log([this.className, ' not hasBeenRendered render spinner on ', this.model.status, this.mode]);
		this.loadingSpinner.show();
		this.$status.text(this.model.status);
		if (this.model.isLoaded) {
			Logger.log([this.className, 'is loaded spinner off', this.model]);
			this.loadingSpinner.hide();
			this.drawMap();
			this.hasBeenRendered = true;
		} else {
			Logger.log([this.className, 'is not loaded spinner NOT off', this.model]);
		}
	}
}
//
// A view with a single map that displays markers only.
//
// selector - a string with a jquery selector for the map canvas element
// model - a CampingModel
//
export class MarkersOnlyView extends MapBaseView {
	constructor(model, options) {
		super(model, options);
		this.className = 'AllCampingView';
		this.options = options;
		this.fit_bounds = options.fit_bounds;
		if (!this.fit_bounds) {
			if ((!Object.hasOwnProperty.call(options, 'center_lat')) || (!Object.hasOwnProperty.call(options, 'center_lng')) || (!Object.hasOwnProperty.call(options, 'zoom'))) {
				throw new Error('MarkersOnlyView - must be either fit_bounds or a center and zoom provided');
			}
		}
	}

	drawMap() {
		const options = this.options;
		const m = new MapManager(this.$canvas[0], this.options);
		m.addMarkers(this.model.payload.markers);
		if (!this.fit_bounds) {
			const center_lat = parseFloat(options.center_lat);
			const center_lng = parseFloat(options.center_lng);
			const zoom = parseInt(options.zoom, 10);
			m.setExplicitCenterAndZoom([center_lat, center_lng], zoom);
		} else {
			m.fitTo();
		}
	}
}
//
// A view that optionally displays camping markers and optionally displays a polyline connecting the markers
//
// options - to control what is displayed
// model - a CampingModel
//
//
export class MarkersAsPolylineView extends MapBaseView {
	constructor(options, model) {
		super(options.div, model);
		this.className = 'PolyLineView';
		this.options = options;
		this.bounds = options.bounds;
	}

	drawMap() {
		const m = new MapManager(this.$canvas[0], this.options);
		if (this.options.display_markers) {
			m.addMarkers(this.model.payload.markers);
		}

		m.peekBounds('MarkerAsPolyline::draw 1');

		if (this.options.display_polyline) {
			m.addPolylineFromMarkers(this.model.data.markers);
		}

		m.peekBounds('MarkerAsPolyline::draw 2');

		m.fitToMarkerBounds();
	}
}

//
// A view that displays camping markers and a polyline for the track data
//
// selector - a string with a jquery selector for the map canvas element
// model - a CurrentTripModel
//
export class CurrentTripView extends MapBaseView {
	constructor(model, options) {
		super(model, options);
		this.className = 'CurrentTripView';
		this.opts = options;
		this.fit_bounds = options.fit_bounds;
		if (!this.fit_bounds) {
			if ((!Object.hasOwnProperty.call(options, 'center_lat')) || (!Object.hasOwnProperty.call(options, 'center_lng')) || (!Object.hasOwnProperty.call(options, 'zoom'))) {
				throw new Error('MarkersOnlyView - must be either fit_bounds or a center and zoom provided');
			}
		}
	}

	drawMap() {
		const m = new MapManager(this.$canvas[0], this.options);
		m.addMarkers(this.model.payload.markers);
		m.addTracks(this.model.payload.tracks);
		if (!this.fit_bounds) {
			const center_lat = parseFloat(this.opts.center_lat);
			const center_lng = parseFloat(this.opts.center_lng);
			const zoom = parseInt(this.opts.zoom, 10);
			m.setExplicitCenterAndZoom([center_lat, center_lng], zoom);
		} else {
			m.fitToBounds();
		}
	}
}


//
// A composite view that makes a page with an MarkersOnlyView at the top
// of the page and a CurrentTripView below. Simple arrangement, works well for
// mobile devices
//
export class TwoMapView {
	constructor(selector, allCampingModel, currentTripModel) {
		this.allCampingView 	= new MarkersOnlyView('#all-camping', allCampingModel, null);
		this.currentTripView	= new CurrentTripView('#current', currentTripModel, null);
	}
	// dont need a render method as the two subviews auto update on model change
}

//
// A composite view that makes a tabbed page with an allCampingView on one tab and
// CurrentTripView on the other tab
//
export class TabView {
	constructor(selector, allCampingModel, currentTripModel) {
		this.subViews = {
			'#all-camping' 	: new MarkersOnlyView('#all-camping', allCampingModel, null),
			'#current'		: new CurrentTripView('#current', currentTripModel, null),
		};

		this.tabWidget = new TabWidget('#tabs');
		this.tabWidget.on('create', (ui) => {
			Logger.log(['TabView on create', ui]);
			const k = ui.key;
			this.subViews[k].render();
		});

		this.tabWidget.on('change', (ui) => {
			Logger.log(['TabView on change', ui]);
			const k = ui.newKey;
			this.subViews[k].render();
		});

		this.tabWidget.setFirstTab('#all-camping');
	}
}


// module.exports = {
// 	MarkersOnlyView,
// 	CurrentTripView,
// 	TabView,
// 	TwoMapView,
// 	MarkersAsPolyline,
// };
