import * as Logger from 'WaLogger'; 

const $ = require('jquery');
const _ = require('lodash');
const blueimpGallery = require('blueimp-gallery');

const template = _.template;

const openSlider = function openSlider(images, startIndex) {
	const addHtml = function addHtml() {
		const html = `<div id='blueimp-gallery'
			class='blueimp-gallery blueimp-gallery-indicator blueimp-gallery-controls blueimp-gallery-fullscreen'>
			<div class='slides'></div> 
			<h3 class='title'></h3> 
			<a class='prev'>‹</a> 
			<a class='next'>›</a> 
			<a class='close'>×</a> 
			<a class='play-pause'></a> 
			<ol class='indicator'></ol> 
			</div>`;
		$('body').append(html);
	};

	const items = images;
	const options = {
		fullScreen: false,
		startSlideshow: false,
		index: startIndex,
		slideshowInterval: 4500,
		preloadRange: 4,
		onclosed: function onClosed() {
			Logger.log(['gallery closed', this]);
			$('#blueimp-gallery').remove();
		},
	};
	addHtml();
	blueimpGallery(items, options);
};


/**
* Looks through the current page for the given img selector.
*
* Take each found image and make it into a 'thumbnail div' by 'rwapping' an new but equivalent <img> tag.
*
* All thumbnail divs will be the same height and width so that the collection of thumbnail
* divs make a regular grid. The height and width is selected to ensure that the largest
* found image can be accomodated without clipping.
*
* Within a thumbnail div the img element will be its natural size.
* So that irregularly propoertioned images may have additional white space to sill out the div to the common size.
*/
// const findAndPrepareThumbnailGrids = function findASndPrepare(selector) {
export default function findASndPrepare(selector) {
	const thumbnailGridEls = $(selector);
	if (thumbnailGridEls.length === 0) {
		return;
	}
	// loop over grid rowns
	thumbnailGridEls.each((grid_element_ix, grid_element) => {
		//
		// classes to put on the element that wraps the img tag
		//
		const images = [];
		const wrapClass = 'gallery-thumbnail xthumbnail';
		const tmplString = `<div 
				class='<%= wrapClass %>' 
				style='overflow:hidden; width:<%= w %>px; height:<%= h %>px; display:inline-block; verticle-align:center; position:relative; cursor:pointer'>
			</div>`;
		const tempFn = template(tmplString);

		const w = $(grid_element).attr('data-width');
		const h = $(grid_element).attr('data-height');
		if (w === undefined || h === undefined) {
			throw new Error(`element [${selector}] does not have valid data-width or data-height attribute`);
		}
		Logger.log([w, h]);

		// loop over the thumbnails in the current grid row
		// uses wrapClass, w, h from outter scope
		$(grid_element).find('img').each((ix, el) => {
			const obj = {
				thumbnail : $(el).attr('src'),
				href	: $(el).attr('data-image'),
				title 	: $(el).attr('data-title'),
				type 	: $(el).attr('data-type'),
			};

			if (obj.thumbnail === undefined || obj.href === undefined || obj.title === undefined || obj.type === undefined) {
				throw new Error(`element [${+selector} img ] does not have valid src, data-image, data-title or data-type attribute`);
			}

			$(el).css('display', 'block');
			$(el).css('position', 'absolute');
			$(el).css('top', '50%');
			$(el).css('transform', 'translateY(-50%)');

			const html = tempFn({ wrapClass, w, h });
			$(el).wrap(html);
			// var style = $(el).attr('style');
			images.push(obj);
			$(el).click(() => {
				openSlider(images, ix);
			});
		});
	});
}

// module.exports = findAndPrepareThumbnailGrids;
