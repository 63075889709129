import * as Logger from 'WaLogger';
const $ = require('jquery');
/**
 * fadein the current target in the carousel
 */
async function async_fadein(carousel, duration, opacity)
{
	return new Promise((resolve, reject) => {
		carousel.target.fadeIn(durations, opacity, () => {
			resolve("Fadein done")
		})
	})
}
/**
 * fadeout the current target and then update the target
 * in the carousel 
*/
async function async_fadeout(target, duration, opacity)
{
	return new Promise((resolve, reject) => {
		carousel.target.fadeOut(durations, opacity, () => {
			resolve("Fadeout done")
		})
	})
}
/*
* an async delay function
*/
async function async_wait(carousel, duration)
{
	return new Promise((resolve, reject)=>{
		setTimeout(()=>{
			resolve()
		})
	})
}
async function async_render_carousel(carousel)
{
	carousel.setTargetImageTo(me.start_index);
	while(true) {
		await async_fadein(carousel)
		await async_wait(carousel.display_duration_ms)
		carousel.start_index = (1 + carousel.start_index) % carousel.sources.length;
		Logger.writeln(`async_render: starting start_index: ${carousel.start_index}`);
		carousel.setTargetImageTo(carousel.start_index);
		await async_fadeout(carousel)
	}
}
/*
** This class represents a single image carousel. An instance is configured by passing index
** an options hash
**
**  options{
**      target_selector:   a jquery selector that gives the <div> that wrap
**                          <img> tag that will display the banner images
**      sources_selector;   a jquery selector that gives a single <div>
**                          and all the <img> tags within that <div> hold the url of the source images
**      displaytime: display period as number of millisecs
*/
function Carousel(options) {
	const me = this;
	
	// this.async_render = async function() {
	// 	me.setTargetImageTo(me.start_index);
	// 	while(true) {
	// 		await async_fadein(carousel)
	// 		await async_wait(carousel.display_duration_ms)
	// 		me.start_index = (1 + me.start_index) % me.sources.length;
	// 		Logger.writeln(`async_render: starting start_index: ${me.start_index}`);
	// 		me.setTargetImageTo(me.start_index);
	// 		await async_fadeout(carousel)
	// 	}
	// }

	this.render = function render() {
		me.setTargetImageTo(me.start_index);
		me.target.fadeTo(me.fade_duration_ms, 1.0, () => {
			me.start()
		});
	};
	/**
	* Starts the carousel, every 5 seconds call update
	*/
	this.start = function start() {
		this.interval = setTimeout(() => {
			me.update();
		}, me.display_duration_ms);
	};

	/**
	* Fades out the current image, when completed determines the next image
	* and fades that image in
	*/
	this.update = function update() {
		Logger.writeln(`SingleImageCarousel::update current_index: ${me.start_index}`);
		me.fadeOut(() => {
			Logger.writeln(`SingleImageCarousel::fadeOut Done ${me.start_index}`)
			me.fadeIn(() => {
				Logger.writeln(`SingleImageCarousel::fadeIn Done ${me.start_index}`);
				me.interval = setTimeout(() => {
					me.update();
				}, me.display_duration_ms)
			})
		});
	};
	this.displayFirstTargetImage = function displayFirstTargetImage() {
		const element = this.target;
		var idx = me.start_index
		Logger.writeln(`displayFirstTargetImage idx: ${idx} me.start_index : ${me.start_index}`);
		$(me.sources[idx].imgObj).css('display:block');
		$(me.sources[idx].imgObj).css('opacity', 1.0);
		const y = me.sources[idx];
		$(element).attr('src', y.src);
	}
	/**
	* Sets the current image in the target div to the image with index  idx
	*/
	this.setTargetImageTo = function setTargetImageTo(idx) {
		const element = this.target;
		Logger.writeln(`show_images idx: ${idx} me.start_index : ${me.start_index}`);
		$(me.sources[idx].imgObj).css('display:block');
		$(me.sources[idx].imgObj).css('opacity', me.fade_in_start_opacity);

		const y = me.sources[idx];
		$(element).attr('src', y.src);
	};
	/**
	* Fades out the current carousel image and then calls the callback function
	*/
	this.fadeOut = (fn) => {
		Logger.writeln(`fadeOut starting`)
		this.target.fadeTo(this.fade_duration_ms, this.fade_out_final_opacity, () => {
			Logger.writeln(`fadeOut callback entered`)
			fn();
		});
	};
	
	/**
	* Calcs the index of the next image to show,
	* fades out the the current image by calling fadeOut
	* sets the new display image to the updated index
	* finally fades in the new image and calls the callback function
	*/
	this.fadeIn = function fadeIn(fn) {
		me.start_index = (1 + me.start_index) % me.sources.length;
		Logger.writeln(`fadeIn: starting start_index: ${me.start_index}`);
		me.setTargetImageTo(me.start_index);
		me.target.fadeTo(me.fade_duration_ms, 1.0, fn);
	};

	this.start_index = 0;
	this.target = options.target_img;
	this.sources = options.images;
	this.current_image = 0;
	this.fade_in_start_opacity = 0.0
	this.fade_out_final_opacity = 0.0
	this.fade_duration_ms = 2000
	this.display_duration_ms = 5000

	if (this.sources.length <= 1) {
		Logger.writeln('ERROR no source images');
	}
}
export {Carousel, async_render_carousel}
// module.exports =  Carousel;
