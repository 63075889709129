import MapManager from 'LeafletMapManager';

const $ = require('jquery');

/**
 * 
 * @returns Types used/expected in this file
 *
 *  * type MapOptions = {
 *      map_type		: "site-map" | "" | "", // ignored
 *      url				: string,  // site relative url required to get the acual marker and track data for the map
 *
 *      fit_bounds 		: bool,                             // default true but ignored
 *      
 *      wrap_selector 	: string,                           // default '#w_map_content',
 *      canvas_selector	: string,                           // default '#w_map_canvas',
 *      spinner_class	: string,                           // default 'map-loading-spinner',
 *      spinner_image	: string,                           // default '/skins/rtw/images/spinner.gif',
 * }
 *
 * type MapDetails = {
 *      markers: Array<Marker>
 *      tracks: Array<Track> | null
 * }
 * type Marker = {
 *      country: string,
 *      date: string,
 *      excerpt: string,
 *      icon_url: string,   // site relative url to the .png image for the marker icon
 *      lat: number,
 *      lng: number,
 *      link: string,       // site relative link to the corresponding post/journal entry
 *      place: string,
 *      title: string,
 *      trip: string,
 *      vehicle: string,
 * }
 * 
 * type Track = {
 *      bounds: Bounds,
 *      color: string,      //such as #ff0000
 *      opacity: number,    // positive
 *      weight: number,     // positive
 *      popitns: Array<Point>
 * }
 * 
 * type Point = {
 *      lat: number, // floating point between -180.0 and +180.0
 *      lng: number  // same
 * }
 * 
 * type Bounds = {
 *      maxlat, minlat, maxlng, minlng: number
 * }
 * 
 */

export function map_init() {
    map_init_1()
}
/**
 * map_init_1 gets the data for and then draws a single map.
 * It uses a "functional" style - a single outter function with inner functions.
 * 
 * Compare to map_init_2 below
 * 
 * @returns 
 */
function map_init_1() {
    
    const options = get_map_options()
    if(options === null)
        //this is not a map page
        return
    let mapwraps = $(options.wrap_selector)
    if(mapwraps.length == 0) {
        //this is not a map page
        return
    } else if(mapwraps.length > 1) {
        throw new Error("wrong number of map wrapper elements")
    }
    const mapwrap = mapwraps[0]
    $(mapwrap).html(
    `
        <div id="w_map_canvas" class="w_map_canvas map_canvas clearfix">
        </div>
    `)
    const map_canvas_el = $(mapwrap).find(`#w_map_canvas`)[0]
    const spinner = new Spinner(map_canvas_el, options)

    spinner.show()
    
    load_data(options.url, function(map_details) {
        spinner.hide()
        drawMap(map_canvas_el, options.center_lat, options.center_lng, options.zoom, map_details)
    })
}
function load_data(url, callback) {
    $.get({
        url: url,
        dataType: 'json', // here it is
        success: (data) => {
            console.log(['CurrentTripModel ajax success', self]);
            const map_details = parseData(data);
            if(map_details == null) {
                throw new Error()
            } else {
                callback(map_details)
            }
        },
        error(xhr, statusText, error) {
            const txt = xhr.responseText;
            alertResponseText(txt);
            throw new Error(`GET failed - bad response from ajax call ${statusText} ${error}`);
        },
    })
}
function parseData(data) {
    if(!data.hasOwnProperty('return_code') && !data.hasOwnProperty('markers')) {
        throw Error("mapper - json data is missing one of return_code markers")
    }
    let md = {
        return_code: data.return_code,
        markers: data.markers,
        tracks: (data.hasOwnProperty('tracks') ? data.tracks: (data.hasOwnProperty('track')) ? [data.track]: null)
    }
    return md
}
function drawMap(canvas_element, center_lat, center_lng, zoom, map_details) {
    // note the MapManager must be created after the spinner is done. Have not worked out why.
    const mm = new MapManager(canvas_element, {
        center_lat: center_lat, 
        center_lng: center_lng, 
        zoom: zoom
    })
    mm.addMarkers(map_details.markers)
    if(map_details.tracks !== null) {
        mm.addTracks(map_details.tracks)
    }
    mm.setExplicitCenterAndZoom([center_lat, center_lng], zoom);
}

///////////////////////////////////////////////////////////////////////////////////
// map_init_2 gets the data for and then draws a single map.
// It uses a OOP style 
///////////////////////////////////////////////////////////////////////////////////
function map_init_2() {
    const options = get_map_options()
    if(options === null)
        //this is not a map page
        return
    let mapwraps = $(options.wrap_selector)
    if(mapwraps.length == 0) {
        //this is not a map page
        return
    } else if(mapwraps.length > 1) {
        throw new Error("wrong number of map wrapper elements")
    }
    const mapwrap = mapwraps[0]
    $(mapwrap).html(
        `
            <div id="w_map_canvas" class="w_map_canvas map_canvas clearfix">
            </div>
        `)
    const map_canvas_el = $(mapwrap).find(`#w_map_canvas`)[0]
    const map = new Map(map_canvas_el, options)
    map.load()
}
class Map {
    constructor(canvas_element, options) {
        this.url = options.url
        this.center_lat = options.center_lat
        this.center_lng = options.center_lng
        this.zoom = options.zoom
        this.canvas_element = canvas_element 
        this.spinner = new Spinner(canvas_element, options);
    }
    show() {
        this.spinner.show()
        this.load()
    }
    load() {
        $.get({
            url: this.url,
            dataType: 'json', // here it is
            success: (data) => {
                setTimeout(() => {
                    console.log(['CurrentTripModel ajax success', self]);
                    const map_details = this.parseData(data);
                    if(map_details == null) {
                        throw new Error()
                    } else {
                        this.spinner.hide()
                        this.draw_map(map_details)
                    }
                }, 0);
            },
            error(xhr, statusText, error) {
                const txt = xhr.responseText;
                alertResponseText(txt);
                throw new Error(`GET failed - bad response from ajax call ${statusText} ${error}`);
            },
        })
    }
    /**
     * 
     * type Track = {
     * }
     * @param {*} data. This is the parsed json data sent by the backend. Its structure should be
     *                  return_code: string
     *                  markers: Array<Marker>
     *                  optionally
     *                      tracks: Array<Track> 
     *                      or 
     *                      track: Track 
     * @returns an object
     *          {
     *              return_code: string
     *              markers: Array<Marker>
     *              tracks: Array<Track> | null
     *          }
     * 
     * @throws 
     *  if the data parameter is not of correct structure
     */
    parseData(data) {
        if(!data.hasOwnProperty('return_code') && !data.hasOwnProperty('markers')) {
            throw Error("mapper - json data is missing one of return_code markers")
        }
        let md = {
            return_code: data.return_code,
            markers: data.markers,
            tracks: (data.hasOwnProperty('tracks') ? data.tracks: (data.hasOwnProperty('track')) ? [data.track]: null)
        }
        return md
    }

    draw_map(map_details) {
        // note the MapManager must be created after the spinner is done. Have not worked out why.
        this.mm = new MapManager(this.canvas_element, {
            center_lat: this.center_lat, 
            center_lng: this.center_lng, 
            zoom: this.zoom
        })
        this.mm.addMarkers(map_details.markers)
        if(map_details.tracks !== null) {
            this.mm.addTracksV2(map_details.tracks)
        }
        this.mm.setExplicitCenterAndZoom([this.center_lat, this.center_lng], this.zoom);
    }
    // refresh() {
    //     this.mm.remove_map()
    //     this.spinner.show()
    //     this.load()
    // }
}
/**
 * 
 * @returns Make a map options object. It has the form
 * type MapOptions = {
     *      map_type		: "site-map" | "" | "",
     *      url				: string,  // site relative url required to get the acual marker and track data for the map
     *
     *  fit_bounds 		: bool,                             // default true,
     *  wrap_selector 	: string,                           //default '#w_map_content',
     *  canvas_selector	: string,                           // default '#w_map_canvas',
     *  spinner_class	: string,                           // default 'map-loading-spinner',
    *   spinner_image	: string,                           // default '/skins/rtw/images/spinner.gif',
     * }
 * }
 */
function get_map_options() {
    /**
     * Map options are attached to the global windows object by the php code that builds the
     * host provided html page.
     * 
     * If the options are not found that idicates that this page is NOT a map page so there
     * is nothing to do. Similarly  if the global variable is dfined but its type is not an 'object'.
     */
    if (typeof window.WhiteacornMap === 'undefined') {
		console.log(['mapBehavior not required ']);
		return null;
	}
	if (typeof window.WhiteacornMap !== 'object'
		|| window.WhiteacornMap == null
		|| (typeof window.WhiteacornMap === 'object' && typeof window.WhiteacornMap.options === 'undefined')
		|| (typeof window.WhiteacornMap === 'object'
				&& typeof window.WhiteacornMap.options === 'object'
				&& typeof window.WhiteacornMap.options.map_type === 'undefined')
	) {
		console.log(['mapv2 get_map_options  ERROR malformed options ', window.WhiteacornMap]);
		throw new Error('mapv2 get_map_options ERROR malformed options ');
	}
    /**
     * Generally the options provided in the map page will not specify the values:
     *  wrap_selector 	: '#w_map_content',
     *  canvas_selector	: '#w_map_canvas',
     *  spinner_class	: 'map-loading-spinner',
     *  spinner_image	: '/skins/rtw/images/spinner.gif',
     *
     * These are added to the options object that will be rreturned.
     */
    const default_options = {
        wrap_selector 	: '#w_map_content',
        canvas_selector	: '#w_map_canvas',
        spinner_class	: 'map-loading-spinner',
        spinner_image	: '/skins/rtw/images/spinner.gif',
       }
	const opts = window.WhiteacornMap.options;

	const options = Object.assign(default_options, opts);
    return options       
}

function Spinner(canvas_element, options) {
    // const canvas_selector = `${options.wrap_selector} > ${options.canvas_selector}`
    // const canvas_element = $(canvas_selector)[0]

	console.log(['Spinner', canvas_element]);
	this.el = canvas_element
	this.$el = $(canvas_element)
    this.spinner_image = options.spinner_image
	this.spinner_class = options.spinner_class
	this.spinner_class_selector = `.${this.spinner_class}`
	this.show = () => {
		const sp = this.$el.find(`${this.spinner_class_selector}`)
		console.log(['MapSpinner:show', this.$el, sp.length])
		if (sp.length === 0) {
			// $(this.selector).html('<div class='map-loading-spinner'><img src='/skins/rtw/images/spinner.gif'></img></div>')
			const html = `<div class=${this.spinner_class}><img src=${this.spinner_image}></img></div>`
			this.$el.html(html)
			this.sel_width = this.$el.width()
			this.sel_height = this.$el.height()
			const $el = this.$el.find('.map-loading-spinner')
			const h = $el.outerHeight()
			const w = $el.outerWidth()
			const h2 = (this.sel_height - h) / 2.0;
			const w2 = (this.sel_width - w) / 2.0;
			$el.css('margin-top', h2)
			$el.css('margin-bottom', h2)
			$el.css('margin-left', w2)
			$el.css('margin-right', w2)
		}
	}

	this.hide = () => {
		const sp = this.$el.find(`${this.spinner_class_selector}`)
		console.log(['MapSpinner:hide', this.$el, sp])
		if (sp.length > 0) {
			sp.remove()
		}
	}
}
function alertResponseText(xhr_response_text) {
	alert(xhr_response_text); // eslint-disable-line no-alert
}
