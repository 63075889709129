import * as Logger from 'WaLogger';
import Breakpoints from 'breakpoints';

const $ = require('jquery');

let state = 'UNKOWN';
let bp_small;
let bp_big;
let isGoing = false;


export const register = function register(big_cb, small_cb) {
	$('body').on('screen:big', () => {
		big_cb();
	});
	$('body').on('screen:small', () => {
		small_cb();
	});
	if ((bp_small !== undefined && bp_small.isMatched !== undefined) && bp_small.isMatched) {
		Logger.log('onBigSmall:register:bp_small ');
		small_cb();
	} else if ((bp_big !== undefined && bp_big.isMatched !== undefined) && bp_big.isMatched) {
		Logger.log('onBigSmall:register:bp_big ');
		big_cb();
	} else {
		Logger.log(['onBigSmall:register:nothing called ', bp_small, bp_big]);
	}
};

//
// you dont need to call this - called in this file as soon as document is ready, only one call processed
//
export const start = function start() {
	Logger.log(['onBigSmall: start:enter', 'isGoing', isGoing, bp_small, bp_big]);
	if (isGoing) {
		return;
	}
	isGoing = true;
	bp_small = Breakpoints.on({
		name: 'SCREEN-SMALL',
		matched: () => {
			if (state !== 'SMALL') {
				Logger.log('onBigSmall:start:matched - triggering SMALL');
				$('body').trigger('screen:small');
				state = 'SMALL';
			}
		},
		exit : () => {
		},
	});

	bp_big = Breakpoints.on({
		name: 'SCREEN-MEDIUM',
		matched: () => {
			if (state !== 'BIG') {
				Logger.log('onBigSmall:start:matched - triggering BIG');
				$('body').trigger('screen:big');
				state = 'BIG';
			}
		},
		exit : () => {
		},
	});
	Logger.log(['onBigSmall: start:return', 'isGoing', isGoing, bp_small, bp_big]);
};
