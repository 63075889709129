/*! Pushy - v1.0.0 - 2016-3-1
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */
var $ = require('jquery')

/**
*	options:
*
*	menu_wrapper	a jquery set of length 1. Contains a div tag that wraps the <ul></ul> that defined
*					the menu items. Default $(".pushy")
*
*	menu_side		"left" or "right", default 'left'
*
*	menu_button		a jquery set of length 1 containing the button element that triggers the menu to open.
*					default $("#menu-btn") 
*
*	siteOverlay		a jquery set of length 1 containing and empty div that will be used to overlay the
*					page to detect clicks outside the menu. Such clicks close the menu.  If not provided
*					pushy will add a suitable element at the end of the <body>
*
*	page_elements	a jquery set of page elements that have to be moved when the menu slides out
*					if not provided pushy wil calculate this set as all direct children of <body>
*					element excluding siteOverlay and menu_wrapper
*/

const DIRECTION_LEFT  = "pushy-left"
const DIRECTION_RIGHT = "pushy-right"

const BODY_CLASS_LEFT = "pushy-open-left"
const BODY_CLASS_RIGHT= "push-open-right"

const CLASS_SUBMENU 		= '.pushy-submenu'
const CLASS_SUBMENU_OPEN	= 'pushy-submenu-open'
const CLASS_SUBMENU_CLOSED 	= 'pushy-submenu-closed'

function csstransforms3d(){
	var el = document.createElement('p'),
	supported = false,
	transforms = {
		'webkitTransform':'-webkit-transform',
		'OTransform':'-o-transform',
		'msTransform':'-ms-transform',
		'MozTransform':'-moz-transform',
		'transform':'transform'
	};

	// Add it to the body to get the computed style
	document.body.insertBefore(el, null);

	for(var t in transforms){
		if( el.style[t] !== undefined ){
			el.style[t] = 'translate3d(1px,1px,1px)';
			supported = window.getComputedStyle(el).getPropertyValue(transforms[t]);
		}
	}

	document.body.removeChild(el);

	return (supported !== undefined && supported.length > 0 && supported !== "none");
}

var cssTransforms3d_supported

var instance_count = 0;

var Pushy = module.exports = function Pushy (options) {

	// cannot do this until document is loaded/ready
	cssTransforms3d_supported = csstransforms3d() && false;

	instance_count++;
	this.id_pushy = instance_count
	this.body = $('body')
	this.pushy 		= options.pushy 
	this.container	= $(".xyz") //make it empty
	this.siteOverlay = options.overlay
	this.siteOverlay.my_id = instance_count

	this.menu_button	= options.button
	this.push 		= options.page_elements
	this.direction   = (options.direction != undefined )? options.direction :  DIRECTION_LEFT
	this.tag 		= (options.tag !== undefined) ? options.tag : "pushy undefined"

	this.opened = false

	if( this.pushy.length == 0){
		console.log("Pushy : bad root")
		throw new Error("Pushy bad root")
	}		
	var root_tag = (this.pushy[0].nodeName).toUpperCase()
	var root_ul = this.pushy.find("ul.left-menu").not(".left-sub-menu")
	if( root_tag !== "DIV" || root_ul.length !== 1){
		console.log("Pushy : bad root tag: " + root_tag + " root_ul length : " + root_ul.length)
		throw new Error("Pushy bad root")
	}

	
		
	var	clickAbleLinks = $('.pushy-link')
	var	clickAbles = this.menu_button.add(clickAbleLinks)
	var	menuBtn = $('.pushy-menu-btn, .pushy-link') //css classes to toggle the menu
	this.menuBtn = clickAbles;

	this.menuSpeed = 300 //jQuery fallback menu speed
	this.menuWidth = this.pushy.width() + 'px' //jQuery fallback menu width

	var	$submenu_list = $(CLASS_SUBMENU)

	function toggleSubmenu(){
		//hide submenu by default
		$($submenu_list).addClass(CLASS_SUBMENU_CLOSED);

		$submenu_list.on('click', function(){
			var selected = $(this);

			if( selected.hasClass(CLASS_SUBMENU_CLOSED) ) {
				//hide opened submenus
				$submenu_list.addClass(CLASS_SUBMENU_CLOSED).removeClass(CLASS_SUBMENU_OPEN);
				//show submenu
				selected.removeClass(CLASS_SUBMENU_CLOSED).addClass(CLASS_SUBMENU_OPEN);
			}else{
				//hide submenu
				selected.addClass(CLASS_SUBMENU_CLOSED).removeClass(CLASS_SUBMENU_OPEN);
			}
		});
	}

	function toggleSubmenuFallback(){
		//hide submenu by default
		$submenu_list.addClass(CLASS_SUBMENU_CLOSED);
	
		$submenu_list.children('a').on('click', function(event){
			event.preventDefault();
			$(this).toggleClass(CLASS_SUBMENU_OPEN)
				   .next('.pushy-submenu ul').slideToggle(200)
				   .end().parent(CLASS_SUBMENU)
				   .siblings(CLASS_SUBMENU).children('a')
				   .removeClass(CLASS_SUBMENU_OPEN)
				   .next('.pushy-submenu ul').slideUp(200);
		});
	}

	if(cssTransforms3d_supported  )
	{
		this.pushy.css({'visibility': 'visible'});	//make menu visible
		toggleSubmenu();							//toggle submenu
		// click handlers for menu button and overlay
		this.menu_button.on('click', function(){
			if( ! this.opened )
				this.openPushy.apply(this)
		}.bind(this))
		this.siteOverlay.on('click', function(){
			if( this.opened )
				this.closePushy.apply(this)
		}.bind(this))
	}
	else
	{
		this.body.addClass('no-csstransforms3d'); 			// record we are not using css
		if( this.pushy.hasClass(DIRECTION_LEFT) ) 			// slide left/right out of sigh
			this.pushy.css({left: "-" + this.menuWidth});
		else
			pushy.css({right: "-" + this.menuWidth});
		this.pushy.css({'visibility': 'visible'}); 			// make visible
		this.container.css({"overflow-x": "hidden"});		// fix IE scollbar issue - MAKES NO SENSE NOW
		toggleSubmenuFallback();							// toggle submenu ??

		// click handlers for menu button and overlay
		this.menu_button.on('click', function(){
			if( ! this.opened )
				this.openPushyFallback.apply(this)
		}.bind(this));

		this.siteOverlay.on('click', function(){
			if (this.opened) 
				this.closePushyFallback.apply(this);
		}.bind(this));
	}
};

/**
* Open the side menu with css transforms
*/
Pushy.prototype.openPushy = function(){
	this.opened = true
	this.pushy.addClass("pushy-open")
	if( this.pushy.hasClass(DIRECTION_LEFT) ){
		this.body.addClass(BODY_CLASS_LEFT);
		this.push.addClass("pushy-push")
	}else{
		this.body.addClass(BODY_CLASS_RIGHT);
		this.push.addClass("pushy-push")
	}
}

/**
* Close the side menu with css transforms
*/
Pushy.prototype.closePushy = function(){
	this.opened = false
	this.pushy.removeClass("pushy-closed")
	if( this.pushy.hasClass(DIRECTION_LEFT) ){
		this.body.removeClass(BODY_CLASS_LEFT);
		this.push.removeClass("pushy-push")
	}else{
		this.body.removeClass(BODY_CLASS_RIGHT);
		this.push.removeClass("pushy-push")
	}
}

/**
* Open the side menu with jquery animation
*/
Pushy.prototype.openPushyFallback = function openPushyFallback(){		
	this.opened = true
	this.pushy.addClass("pushy-open")
	this.push.css("position","relative")
	if( this.pushy.hasClass(DIRECTION_LEFT) ){
		this.body.addClass(BODY_CLASS_LEFT);
		this.pushy.animate({left: "0px"}, this.menuSpeed);
		this.container.animate({left: this.menuWidth}, this.menuSpeed);		
		this.push.animate({left: this.menuWidth}, this.menuSpeed);
	}else{
		this.body.addClass(BODY_CLASS_RIGHT);
		this.pushy.animate({right: '0px'}, this.menuSpeed);
		this.container.animate({right: this.menudWith}, this.menuSpeed);
		push.animate({right: this.menuWidth}, this.menuSpeed);
	}

}

/**
* Close the side menu with jquery animation
*/
Pushy.prototype.closePushyFallback = function closePushyFallback(){
	this.opened = false
	this.pushy.addClass("pushy-closed")
	this.push.css("position","")
	if( this.pushy.hasClass(DIRECTION_LEFT) ){
		this.body.removeClass(BODY_CLASS_LEFT);
		this.pushy.animate({left: "-" + this.menuWidth}, this.menuSpeed);
		this.container.animate({left: "0px"}, this.menuSpeed);
		this.push.animate({left: "0px"}, this.menuSpeed);
	}else{
		this.body.removeClass(BODY_CLASS_RIGHT);
		this.pushy.animate({right: "-" + this.menuWidth}, this.menuSpeed);
		this.container.animate({right: "0px"}, this.menuSpeed);
		this.push.animate({right: "0px"}, this.menuSpeed);
	}

}



