import * as Logger from 'WaLogger';

const $ = require('jquery');

//
// A 'please wait - loading' rotating image
//
// parameters
//	-	el a DOM element inside of which we put the spinner. Assume its a div
//		NOT a jquery object
//
export default function MapSpinner(el, options) {
	Logger.log(['MapSpinner', el]);
	this.options = options;
	this.el = el;
	this.$el = $(el);
	this.spinner_class = this.options.spinner_class;
	this.spinner_class_selector = `.${this.spinner_class}`;
	this.show = () => {
		const sp = this.$el.find(`${this.options.spinner_class_selector}`);
		Logger.log(['MapSpinner:show', this.$el, sp.length]);
		if (sp.length === 0) {
			// $(this.selector).html('<div class='map-loading-spinner'><img src='/skins/rtw/images/spinner.gif'></img></div>')
			const html = `<div class=${this.options.spinner_class}><img src=${this.options.spinner_image}></img></div>`;
			this.$el.html(html);
			this.sel_width = this.$el.width();
			this.sel_height = this.$el.height();
			const $el = this.$el.find('.map-loading-spinner');
			const h = $el.outerHeight();
			const w = $el.outerWidth();
			const h2 = (this.sel_height - h) / 2.0;
			const w2 = (this.sel_width - w) / 2.0;
			$el.css('margin-top', h2);
			$el.css('margin-bottom', h2);
			$el.css('margin-left', w2);
			$el.css('margin-right', w2);
		}
	};

	this.hide = () => {
		const sp = this.$el.find(`${this.options.spinner_class_selector}`);
		Logger.log(['MapSpinner:hide', this.$el, sp]);
		if (sp.length > 0) {
			sp.remove();
		}
	};
}
