import * as Logger from 'WaLogger';
/**
 * Attach a click handler to each item-summary and when clicked
 * find the href of the descendant nnode that is an anchor and link to that
 */
// const initialize = function init() {
export function initialize() {
        const elements = document.querySelectorAll(".item-summary")
	elements.forEach((el, index) => {
		Logger.writeln(["post-summary ", el, index])
		el.addEventListener("click", (event) => {
			const a = event.currentTarget.querySelectorAll("a.item-link")
			if(a.length !== 1) {
				throw new Error("post-summary did not find exactly one anchor")
			}
			const h = a[0].href
			Logger.writeln("in click handler")
			window.location = h
		})
	})
}
