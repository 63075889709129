import * as Logger from 'WaLogger';
import * as MapModel from 'MapModels';
import * as MapView from 'MapViews';

const $ = require('jquery');
const Lodash_ = require('lodash');

//
// Controller for the traditional map of all of our camping places
//
export function SiteCampingMapController(options) {
	const opts = options;
	Logger.log([opts]);
	this.model = new MapModel.CampingModel(opts.url);
	this.view = new MapView.MarkersOnlyView(this.model, opts);
	this.model.load();
}
//
// Controller for the traditional map of all of our camping places for a single trip
//
export function MarkersOnlyMapController(options) {
	const opts = options;
	Logger.log([opts]);
	this.model = new MapModel.CampingModel(opts.url);
	this.view = new MapView.MarkersOnlyView(this.model, opts);
	this.model.load();
}
//
// A controller for a page that hows a single map for the current trip
// with camping places and the track for this trip
//
export function MarkersWithTrackMapController(options) {
	const opts = options;
	Logger.log([opts]);
	this.model = new MapModel.CurrentTripModel(opts.url);
	this.view = new MapView.CurrentTripView(this.model, options);
	this.model.load();
}
//
// Map a page with two maps each one on s separate tab
// First tab is map of all camping places
// Second tab is current trip camping places and track
//
export function TabbedMapController(options) {
	const opts = Lodash_.defaults(options, {

		selectors,
		tab_selector			: '#tabs',
		canvas_class: selector.map_canvas_class,
		all_camping : {
			div				: selectors.all_camping_id,
			url				: '/locations/json/rtw',
			center_lat		: 44.4444,
			center_lng		: 123.111,
			size			: 4,
		},
		current_trip : {
			div				: selectors.current_id,
			url 			: '/current_locations/json',
			center_lat		: 44.4444,
			center_lng		: 123.111,
			size			: 4,
		},
	});

	// const campingMapDiv = $(opts.all_camping.div);
	// const currentTripMapDiv = $(opts.current_trip.div);
	this.allCampingModel = new MapModel.CampingModel(opts.all_camping.url);
	this.currentTripModel = new MapModel.CurrentTripModel(opts.current_trip.url);

	this.view = new MapView.TabView(opts.tabSelector, this.allCampingModel, this.currentTripModel);

	this.allCampingModel.load();
	this.currentTripModel.load();
}

//
// Map a page with two maps one on top of the other. Scroll down to see the second one
// Top map is all camping places
// Lower map is current trip camping places and track
//
export function TwoMapController(options) {
	const opts = Lodash_.defaults(options, {

		selectors,
		canvas_class: selectors.map_canvas_class,
		all_camping : {
			div				: selectors.all_camping_id,
			url				: '/locations/json/rtw',
			center_lat		: 44.4444,
			center_lng		: 123.111,
			size			: 4,
		},
		current_trip : {
			div				: selectors.current_id,
			url 			: '/current_locations/json',
			center_lat		: 44.4444,
			center_lng		: 123.111,
			size			: 4,
		},
	});

	const campingMapDiv = $(opts.all_camping.div);
	const currentTripMapDiv = $(opts.current_trip.div);

	this.allCampingModel = new MapModel.CampingModel(opts.all_camping.url);
	this.currentTripModel = new MapModel.CurrentTripModel(opts.current_trip.url);

	this.allCampingView = new MapView.MarkersOnlyView(campingMapDiv, this.allCampingModel);
	this.currentTripView = new MapView.CurrentTripView(currentTripMapDiv, this.currentTripModel);

	this.allCampingModel.load();
	this.currentTripModel.load();
}

export function MapOfRouteController(options) {
	const continent = options.m_continent;
	const opts = Lodash_.defaults(options, {
		selectors,
		div			: '#map_content',
		url 		: `/locations/continent/'${continent}`,
		center_lat	: 44.4444,
		center_lng	: 123.111,
		size		: 4,
	});
	const default_opts = {
		selectors,
		div			: '#map_content',
		url 		: `/locations/continent/'${continent}`,
		center_lat	: 44.4444,
		center_lng	: 123.111,
		size		: 4,
	};
	Logger.log([opts]);
	this.model = new MapModel.CampingModel(opts.url);
	this.view = new MapView.MarkersAsPolylineView(opts, this.model);
	this.model.load();
}
