import * as Logger from 'WaLogger';
import * as OnBigSmall from 'on-screen-big-small';

const $ = require('jquery');
const jqpt = require('jquery-powertip'); // eslint-disable-line no-unused-vars
const base64 = require('base-64');

export function menuToolTip(selector, placement) {
	const onGoingBig = function onGoingBig() {
		const isGoing = ($('#powerTip').data('powerTip-open') !== undefined && $('#powerTip').data('powerTip-open') === 'yes');
		Logger.log(['onBig main menu called', isGoing]);
		if (!isGoing) {
			$('#powerTip').data('powerTip-open', 'yes').css('width:300px');
			const $elements = $(selector);
			$elements.powerTip({
			// $(selector).powerTip({
				placement,
				smartPlacement:true, // placement, // eslint-disable-line object-shorthand
				fadeInTime: 0,
				fadeOutTime: 0,
				offset: 10,
			});
			$(selector).on({
				// about to render tooltip - give it the content
				powerTipPreRender: function powerTipPreRender() {
					$(this).data('powertip-open', 'yes');
					const text64 = $(this).attr('data-tooltip');
					const html = base64.decode(text64);
					Logger.log('menu tooltip powerPreTipRender', html, text64, this);
					$(this).data('powertip', html);
				},
				powerTipRender : function PowerTipRender() {
					$('#powerTip').css('max-width', '400px');
					$('#powerTip').css('white-space', 'normal');
					Logger.log(['menu tooltip open', this, $('#powerTip')]);
				},
				powerTipOpen : function powerTipOpen() {
					Logger.log(['menu tooltip open', this, $('#powerTip')]);
				},
			});
		}
	};

	const onGoingSmall = function onGoingSmall() {
		Logger.log(['onSmall main menu called']);
		$(selector).powerTip('destroy');
		$('#powerTip').data('powerTip-open', 'no');
	};
	OnBigSmall.register(onGoingBig, onGoingSmall);
}

// module.exports = menuToolTip;
