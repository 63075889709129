import * as Logger from 'WaLogger';
import {menuToolTip} from 'menu-tooltip';

const $ = require('jquery');
const jquery_ui = require('jqueryui'); // eslint-disable-line no-unused-vars

export function initialize() {
	Logger.log(['main-menu', menuToolTip]);
	const a1 = $('#navbar a');
	if (a1.length === 0) {
		Logger.log('mainMenu - selector not found');
		return;
	}
	const select_non_dropdown_items = '#navbar > li:not(.dropdown) > a:not(.dropdown-toggle)';
	const select_dropdown_button = '#navbar > li.dropdown > a.dropdown-toggle';
	const select_dropdown_items = '#navbar > li.dropdown > ul > li:not(.divider)'
	const select_dropdown_anchors = '#navbar > li.dropdown > ul > li:not(.divider) > a';

	const non_dropdown_items = $(select_non_dropdown_items);
	const dropdown_button = $(select_dropdown_button);
	const dropdown_items = $(select_dropdown_items);
	const dropdown_anchors = $(select_dropdown_anchors);

	menuToolTip(select_non_dropdown_items, 'sw');
	menuToolTip(select_dropdown_items, 'e');
	dropdown_items.click((event) => {
		const text = $(event.target).text();
		dropdown_button.text(text);
		console.log([event, $(event.target).text()]);
	});
	dropdown_anchors.click((event) => {
		const text = $(event.target).text();
		dropdown_button.text(text);
		console.log([event, $(event.target).text()]);
	});

	const elements = document.querySelectorAll('.dropdown')
	elements.forEach((el) => {
		el.addEventListener("mouseenter", (ev) => {
			ev.currentTarget.classList.add('open')
			console.log("entered")
		})
		el.addEventListener("mouseleave", (ev) => {
			ev.currentTarget.classList.remove('open')
			console.log("leaving")
		})
	})
	// $('.dropdown').
	// 	on("mouseenter", (event) => {
	// 		event.currentTarget.classList.add('open')
	// 		// $(event.currentTarget).addClass('open')
	// 	}).on("mouseleave", (event)=> {
	// 		event.currentTarget.classList.remove('open')
	// 		// $(event.currentTarget).removeClass('open')
	// 	})
	// $('.dropdown').hover(function x1(event) {
	// 	const el = event.currentTarget
	// 	$(el).addClass('open');
	// },
	// function xx(event) {
	// 	const el = event.currentTarget
	// 	$(el).removeClass('open');
	// });
};
