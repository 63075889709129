import * as Logger from 'WaLogger';

const $ = require('jquery');
const Pushy = require('pushy');


/**
* Sets up to create a Pushy instance to handle a side menu. Needs to know:
*
*	menu-root 				- 	$(ul element that wraps the menu items)
*	menu-button				-	$(button that fires the menu display)
*	elements_to_push_aside	-	$( those elements that should be moved when the menu slides into view)
*	tag 					-	string for debugging only
*
*	The setup for Pushy involves
*	-	testing to see tat the arguments make sense
*	-	cloning the <ul> element and wapping it with <div class='pushy pushy-left'>
*		and prepending the whole thing at the start of the <body>
*	-	adding pushy-link to all <li> elements that are in the menu and are NOT submenus
*	-	creating a single (global) <div class="push-site-overlay site-overlay"></div>. This
*		common overlay will be used by all instances of Pushy
*
*/
export default function CustomPushy(menu_root, menu_button, elements_to_push_aside, tag) {
	this.tag = (tag === undefined) ? 'undefined' : tag;

	Logger.log(['CustomPushy', menu_button, menu_root]);

	if (menu_root.length === 0 || (menu_root[0].nodeName).toUpperCase() !== 'UL') {
		Logger.log('CustomPushy: bad root node');
		throw new Error('CustomPushy: bad root node');
	}
	if (menu_button.length === 0 || (menu_button[0].nodeName).toUpperCase() !== 'BUTTON') {
		Logger.log('CustomPushy: bad button');
		throw new Error('CustomPushy: bad button');
	}

	const top_level_ul = menu_root;
	const button = menu_button;
	const containers = elements_to_push_aside;

	// now wrap the menu for pushy
	const cloned_top_level_ul = top_level_ul.clone();
	const clone_id = cloned_top_level_ul.attr('id');
	cloned_top_level_ul.attr('id', `pushy-id-${clone_id}`);
	cloned_top_level_ul.wrap('<div class="pushy pushy-left"></div>');
	const clone_wrapper = cloned_top_level_ul.parent();

	// make all (sub)menu li tags have class pushy-links
	cloned_top_level_ul.find('li').addClass('pushy-link');
	// correct those li that contain submenus by removing pushy-link
	const m_sub = cloned_top_level_ul.find('.left_sub-menu');
	m_sub.parent('li').addClass('pushy-submenu').removeClass('pushy-link');
	// now should have all li's with correct classes
	// debug reporting
	const c = menu_root.length;
	const c_sub = m_sub.length;
	Logger.log(`leftMenu:: found ${c} menus and ${c_sub} sub menus`);

	$('body').prepend(clone_wrapper);

	// const position = $('#content-column').position();
	//
	// check there is a place for the overlay and if not create it
	//
	let overlay = $('.pushy-site-overlay');
	if (overlay.length === 0) {
		overlay = $('<div class ="pushy-site-overlay site-overlay"></div>').prependTo('body');
	}

	const options = {
		pushy 		: clone_wrapper,
		button 		: button, // eslint-disable-line object-shorthand
		overlay 	: overlay, // eslint-disable-line object-shorthand
		page_elements : containers,
		tag : this.tag,
	};

	Logger.log(['CustomPushy - create pushy', options]);
	// const pushy = this.pushy = new Pushy(options);
	// const x = $("#menu-column a");
};
