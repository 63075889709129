import * as Logger from "WaLogger"
// import item_summary_click from "item-summary-click"; // eslint-disable-line no-unused-vars
import * as MainMenu from "main-menu"
import * as EqualHeight from "equal-height"
import * as PostSummaryEvents from "post-summary"
import leftMenu from "left-menu"
import Gallery from "gallery"
import Banner from "banner"
import Vehicle from "vehicle"
import * as mapBehavior from "map-behavior"
import * as Mapv2 from "mapv2"
import notImplemented from "not-implemented"
import * as OnBigSmall from "on-screen-big-small"

const $ = require("jquery")
const bs = require("bootstrap") // eslint-disable-line no-unused-vars

Logger.enable()
Logger.log(" initializing behaviors")
Logger.disable()

/*
class App {
  initialize() {}

  onDocReady() {
    Logger.log(" dom ready")
    EqualHeight.initialize(".eq-col")
    Banner("#banner")
    Gallery(".gallery.thumbnails")
    Vehicle()
    MainMenu.initialize()
    OnBigSmall.start()
    this.vehicleLeftMenu = leftMenu.vehicleMenu()
    this.campingLeftMenu = leftMenu.campingMenu()
    this.howLeftMenu = leftMenu.howMenu()
    this.archiveLeftMenus = leftMenu.archiveMenu()
    PostSummaryEvents.initialize()
    notImplemented.initialize()
    mapBehavior.initialize()
  }

//   onWindowLoad() {
//     Logger.log("window ready")
//     EqualHeight.initialize(".eq-col")
//   }
}
window.app = new App()

// $(window).on("load", () => {
//   Logger.log("window ready")
//   window.app.onWindowLoad()
// })

// $(document).ready(() => {
//   window.app.onDocReady()
// })
*/

/**
 * When the dom is ready - fire off the javascript
 */
$(function(){
    Logger.log(" dom ready")
    Banner("#banner")
    const logo = $("#australia-logo > img")
    if(logo.length >= 1) {
        setTimeout(() => {
            logo.show()
            EqualHeight.initialize(".eq-col")
        }, 3000)
    }
    Gallery(".gallery.thumbnails")
    Vehicle()
    MainMenu.initialize()
    EqualHeight.initialize(".eq-col")
    OnBigSmall.start()
    this.vehicleLeftMenu = leftMenu.vehicleMenu()
    this.campingLeftMenu = leftMenu.campingMenu()
    this.howLeftMenu = leftMenu.howMenu()
    this.archiveLeftMenus = leftMenu.archiveMenu()
    PostSummaryEvents.initialize()
    notImplemented.initialize()
    // mapBehavior.initialize()
    Mapv2.map_init()

})
