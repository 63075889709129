import * as Logger from "WaLogger";

const $ = require("jquery");
const Lodash_  = require("lodash");
const L = require("leaflet");
const MapboxLeaflet = require("mapbox-gl-leaflet");
const Usemapbox = true;

const MapboxTokenSecret =
	"sk.eyJ1IjoicmJsYWNrd2VsbCIsImEiOiJjanpoaXB0NGcwc3NwM2NwNnNtb2t1eHY5In0.yQ6JvxQJJapkKF0am17q0w";
const MapboxToken =
	"pk.eyJ1IjoicmJsYWNrd2VsbCIsImEiOiJjajhvcDh6enowNjByMnpvMGowbm4xOHBlIn0.DBvi5kON6L_sT0gs4HRb3Q";

function iconFromUrl(url)
{
	const icon = L.icon({
		iconUrl: url,
		iconSize: [15, 21],
		iconAnchor: [8, 21],
		popupAnchor: [-3, -76]
	});
	return icon;
}
function popupFactory(marker_data) {
	const tmpl =
		"<div class='map-infowindow-wrapper marker' > " +
		"<h3><%= title %></h3>" +
		"<p><%= date %> : <%= place %>, <%= country%></p>" +
		"<a href='<%= link %>' ><span> ... click for details</span></a>" +
		"</div>";

	const tmplFun = Lodash_.template(tmpl);
	// const link = marker_data.link;
	// const newLink = "'" + link + "'";
	const nHtml = tmplFun(marker_data);
	const newHtml = nHtml;
	let popupdiv = $("#popupdiv");
	if (popupdiv.length === 0) {
		$("body").append("<div id='popupdiv'></div>");
		popupdiv = $("#popupdiv");
	}
	popupdiv.empty();
	popupdiv.append(newHtml);
	const ret = document.getElementById("popupdiv");
	return ret;
}

export default class LeafletMapHandler {
	constructor(canvasEl, options) {
		this.debug = false;
		// this.options = options;
		this.gpx_content = null;
		this.gpx_parser = null;
		this.center_lat = options.center_lat;
		this.center_lng = options.center_lng;
		this.zoom = options.zoom;
		this.el = canvasEl;
		this.bounds = L.latLngBounds();
        //experiment with minZoom
		// this.map = L.map("w_map_canvas", {
        this.map = L.map(canvasEl, {
            center: [this.center_lat, this.center_lng],
			zoom: this.zoom,
			scrollWheelZoom: false,
			tap: false,
			gestureHandling: true,
            minZoom: this.zoom
		});
        this.zoom_level = this.map.getZoom()


		if (!Usemapbox) {
			// L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
			L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			}).addTo(this.map);
		} else {
			this.gl = L.mapboxGL({
				accessToken: MapboxToken,
				// style: 'mapbox://styles/mapbox/bright-v8',
				style: "mapbox://styles/mapbox/bright-v9"
				// style: "mapbox://styles/mapbox/streets-v12"
			}).addTo(this.map);
		}
		this.map.on("load", () => {
			// let z = this.map.getBoundsZoom(this.bounds);
		});
		this.map.on("resize", () => {
            console.log("map resized")
			const z = this.map.getBoundsZoom(this.bounds);
			this.map.setZoom(z);
			this.map.fitBounds(this.bounds);
		});
        this.map.on("zoomstart", function(event) {
            return
            const z = this.map.getZoom()
            console.log(["zoomstart", z, event.target._animateToZoom])
        }.bind(this))
        this.map.on("zoomend", function() {
            return
            const center = this.map.getCenter()
            const z = this.map.getZoom()
            const {lat, lng} = this.map.getBounds().getCenter()
            console.log(["map zoomed", this.center_lat, this.center_lng, center.lat, center.lng, z, lat, lng])
            if(z < this.zoom) 
                this.map.setView({lat: this.center_lat, lng: this.center_lng}, this.zoom)
        }.bind(this))
	}
	/**
	 * Create a single marker and its corresponding info box and add it to the map
	 */
	// addMarkerToMap(marker_data) {
	// 	// const icon = iconFactory(marker_data);
    //     const icon = iconFromUrl(marker_data.icon_url)
	// 	const popfunc = () => {
	// 		const element = popupFactory(marker_data);
	// 		return element;
	// 	};

	// 	const mker = L.marker([marker_data.lat, marker_data.lng], { icon })
	// 		.addTo(this.map)
	// 		.bindPopup(popfunc);

	// 	return mker;
	// }

	/**
	 * Adds all the markers provided in markers to the current map and recomputes this.bounds
	 * after each addition.
	 */
	addMarkers(markers) {
		this.markers = markers;
		for (let i = 0; i < this.markers.length; i += 1) {
			const marker_data = this.markers[i]
			//
			// flat and flng must be floating point numbers
			//
			const flat = this.markers[i].lat
			const flng = this.markers[i].lng
			this.bounds.extend([flat, flng])

            const icon = iconFromUrl(marker_data.icon_url)
            const popfunc = () => {
                const element = popupFactory(marker_data);
                return element;
            };
    
            const mker = L.marker([marker_data.lat, marker_data.lng], { icon })
                .addTo(this.map)
                .bindPopup(popfunc);
    
			// this.addMarkerToMap(marker_data)
		}
	}

	addTracks(tracks) {
        console.log(["its a multiple polyline map", tracks])
        for(const track of tracks) {
            this.addPolyLine(track.points, track.color, 3)
        }
	}

	/**
	 * Utility function that actually does the heavy liftinig of drawing a track/polyline
	 */
	addPolyLine(points, color, weight) {
		const p = [];
		for (let i = 0; i < points.length; i += 1) {
			p.push([points[i].lat, points[i].lng]);
			this.bounds.extend([[points[i].lat, points[i].lng]]);
		}
		const track = new L.polyline(p, {
			color: color,
			weight: weight
		}); // eslint-disable-line new-cap

		track.addTo(this.map);
	}

	fitTo() {
		const z = this.map.getBoundsZoom(this.bounds);
		this.map.setZoom(z);
	}

	setExplicitCenterAndZoom(center, zoom) {
		try {
			this.map.setView(center, zoom);
		} catch (e) {
			console.log(`error  ${e}`); // eslint-disable-line no-console
		}
	}

	/**
	 * Fits the map center and bounds to include all markers and polylines previously drawn on the map.
	 *
	 * Note that the addMarkers and addTrack methods update this.bounds with each addition
	 */
	fitToBounds() {
		this.map.fitBounds(this.bounds);
	}

	fitToTrack() {
		if (this.trackModel == null) {
			return;
		}
		this.map.fitBounds(this.bounds);
	}

	peekBounds(msg) {
		const ne = this.bounds.getNorthEast();
		const nelat = ne.lat();
		const nelng = ne.lng();
		const sw = this.bounds.getSouthWest();
		const swlat = sw.lat();
		const swlng = sw.lng();
		const s = `mapManager::peekBounds: ${msg} nelat: ${nelat} nelng: ${nelng} swlat: ${swlat} swlng: ${swlng}`;
		if (!this.debug) {
			return;
		}
		Logger.log(s);
	}
    remove_map() {
        this.map.off()
        this.map.remove()
    }
}
