import * as Logger from 'WaLogger';
import {Carousel, async_run_carousel} from 'carousel';

const $ = require('jquery');
// const CarouselExportObject = require('carousel');
// const Carousel = CarouselExportObject..default.Carousel
// const async_run_carousel = CarouselExportObject.default.async_run_carousel

$.fn.imageLoad = function(fn){
    this.on('load', fn);
    this.each( function() {
        if ( this.complete && this.naturalWidth !== 0 ) {
            $(this).trigger('load');
        }
    });
}
export default function Banner(selector) {
	const banner_images = [];
	const me = this
	const wait_for_images_to_load = true
	const els = $(selector);
	if (els.length <= 0) {
		Logger.log('Banner: no banner found on page - nothing to do');
		return;
	}
	if (els.length > 1) {
		Logger.log('Banner: only one banner per page');
		throw new Error('Banner: only one banner per page');
	}
	const banner_el = $(els[0]);
	const width = banner_el.attr('data-width');
	const height = banner_el.attr('data-height');

	if (width === undefined || height === undefined) {
		Logger.log('Banner: no h/w given for banner image');
		throw new Error('Banner: no h/w given for banner image');
	}

	const imgs = banner_el.find(' > img');
	const number_imgs = imgs.length
	if (imgs.length <= 0) {
		Logger.log('Banner: no images found in banner');
		throw new Error('Banner: no images found in banner');
	}
	var carousel;
	var banner_visible_image;
	const addImage = (el) => {
		const obj = {
			imgObj : el,
			parent: el.parentNode,
			otherImages : el.parentNode.children,
			src : $(el).attr('src'),
			width : $(el).attr('data-width'),
			height : $(el).attr('data-height'),
		};
		banner_images.push(obj);
		$(el).css('display', 'none');
		if(imgs.length == banner_images.length) {
			Logger.writeln(`Banner::addImages starting carousel`)
			start_carousel()
		}
	}

	const onImageLoaded = (el) => {
		if(el.complete && el.naturalWidth != 0) {
			addImage(el)
		} else {
			$(el).on('load', () => {
				addImage(el)
			})
		}
	}
	function start_carousel()
	{
		Logger.writeln(`Banner::start_carousel imgs.length: ${imgs.length}`)
		banner_visible_image = $('<img id=\'banner_image\' class=\'\'/>');
		banner_visible_image.css('width', '100%');
		banner_visible_image.css('height', 'auto');
		banner_el.append(banner_visible_image);
		banner_el.css('display', 'block');

		banner_visible_image.wrap('<div id=\'banner-visible-div\' class=\'row\'></div>');
		banner_el.addClass('container');
		banner_visible_image.css('display', 'none');
		//
		// Now have the html setup for the carousel
		//
		carousel = new Carousel({
			images 		: banner_images,
			target_img 	: banner_visible_image,
		});
		carousel.render();
		// async_run_carousel(carousel)
	}

	if(wait_for_images_to_load) {
		imgs.each((ix, el) => {
			onImageLoaded(el)
		})
	} else {
		imgs.each((ix, el) => {
			const obj = {
				imgObj : el,
				parent: el.parentNode,
				otherImages : el.parentNode.children,
				src : $(el).attr('src'),
				width : $(el).attr('data-width'),
				height : $(el).attr('data-height'),
			};
			banner_images.push(obj);
			$(el).css('display', 'none');
		});
		start_carousel()
	}

};

// export default Banner;
// module.exports = Banner;
