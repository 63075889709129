const $ = require('jquery');

const initialize = function init() {
	const $dialog = $('<div></div>');
	$dialog.html('<p>I am sorry this this item is not yet implemented</p><p>I am working on filling out the site</p>');
	$dialog.dialog({
		autoOpen: false,
		title: 'Sorry - Not yet implemented',
		dialogClass: 'not-implemented-dialog',
	});
	const items = document.querySelectorAll(".not_implemented")
	items.forEach((element) => {
		element.addEventListener("click", (ev) => {
			console.log("not implemented")
		})
	})
	$('.not_implemented').click(() => {
		$dialog.dialog('open');
		// prevent the default action, e.g., following a link
		return false;
	});
};
module.exports = { initialize };
