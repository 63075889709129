import * as Logger from 'WaLogger'; 

const $ = require('jquery');
const Breakpoints = require('js-breakpoints');
// @todo Probably redundant
class Media { // eslint-disable-line no-unused-vars
	constructor() {
		Breakpoints.on({
			name: 'SCREEN-EXTRA-SMALL',
			matched: () => {
				Logger.log('vehicleController::Media EXTRA SMALL screen entered');
			},
			exit : () => {
				Logger.log('vehicleController::Media EXTRA SMALL screen EXIT');
			},
		});

		Breakpoints.on({
			name: 'SCREEN-SMALL',
			matched: function smallMatch() {
				Logger.log('vehicleController::Media SMALL screen entered');
			},
			exit : function smallExit() {
				Logger.log('vehicleController::Media SMALL screen EXIT');
			},
		});
		Breakpoints.on({
			name: 'SCREEN-MEDIUM',
			matched: function medMatch() {
				Logger.log('vehicleController::Media MEDIUM screen entered');
			},
			exit : function medExit() {
				Logger.log('vehicleController::Media MEDIUM screen EXIT');
			},
		});
	}
}

export default function vehicle(selector) { // eslint-disable-line no-unused-vars
	if ($('body.vehicle').length === 0) {
		Logger.log('vehicleController : nothing to do');
		return;
	}
	Logger.log('vehicleController : initializing');
};
